
import { mapGetters } from 'vuex';
// import SVGPreFooterBg from '../../assets/svg/prefooter-bg.svg';

export default {
  // components: { SVGPreFooterBg },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      siteParts: 'settings/siteParts'
    })
  }
};
